import { Box, Alert, LinearProgress, Tooltip, IconButton } from '@mui/material';
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import SaveIcon from '@mui/icons-material/Save';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import Steps from '../components/test/Steps';
import Definition from '../components/test/Definition';
import Activities from '../components/test/Activities';
import Campaign from '../components/test/Campaign';
import FourOOne from '../components/general/FourOOne';

import "./Test.css"

import AuthHeader from '../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const Test = (props) => {

  const { test_id } = useParams()
  const [status, setStatus] = useState("loading")
  const [test, setTest] = useState()
  const [show_alert, setShowAlert] = useState(false)
  const [alert_code, setAlertCode] = useState("")

  useEffect(() => {
    props.setScrumbs(status === "loading" ? "Test" : "Test > " + test.name)
  }, [test, status, props])

  useEffect(() => {
    fetch(API + "/test/?id=" + test_id, 
        {
          method: 'GET',
          headers: AuthHeader()
        }
    )
    .then((response) => {            
      if (response.status === 200) {
          return response.json()
      } else if (response.status === 401) {
          setStatus("401")                
          return null
      }
    })
    .then((data) => {
      if (data) {
        setTest(data)
        setStatus("200")
      } 
      
    })
  }, [test_id])
  
  useEffect(() => {
    const interval = setInterval(() => {
      fetch(API + "/test/status/lock/?id=" + test_id, 
          {
            method: 'POST',
            headers: AuthHeader(),
          }
    )
    .then(response => response.json())
    .then(result => {
      
    })
    .catch(error => console.log('error', error));
    }, 1000)
    return () => clearInterval(interval)
  }, [test_id])

  const saveTest = () => {
    if (!checkSafeToSave()) {
      setAlertCode("fail")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else {
      var body = {
          id: test_id,
          definition: test,
      }
      var headers = AuthHeader()
      headers.append("Content-Type", "application/json");
      // Do the request
      fetch(API + "/test/set/", 
            {
              method: 'PUT',
              headers: headers,
              body: JSON.stringify(body),
              redirect: 'follow'
            }
      )
      .then(response => {            
        if (response.status === 200) {
          return response.json()
        } else if (response.status === 401) {       
          setAlertCode("fail")
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(false)
          }, 2000)     
          return null
        }
      })
      .then(data => {
        if (data) {
          setAlertCode(data.status === "ok" ? "ok" : "fail")
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(false)
          }, 2000)
        }        
      })
    }
  }

  const checkSafeToSave = () => {
    let safe = true
    safe = safe && test.name.length > 0
    safe = safe && !test.name.includes(":")
    return safe
  }
  
  const scrap = () => {
    fetch(API + "/task/create/?id=" + test_id + "&engine=" + props.engine_id, 
          {
            method: 'POST',
            headers: AuthHeader()
          }
    )
    .then((response) => response.json())
    .then((data) => {
      
    })
    .catch((error) => console.log(error))
  }

  const archiveTest = () => {
    let new_test = {...test}
    new_test.enabled = !test["enabled"]
    setTest(new_test)

    var body = {
      id: test_id,
      definition: {"enabled": new_test.enabled},
   }
    var headers = AuthHeader()
    headers.append("Content-Type", "application/json");
    // Do the request
    fetch(API + "/test/set/", 
          {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
            redirect: 'follow'
          }
    )
    .then(response => {            
      if (response.status === 200) {
        return response.json()
      } else if (response.status === 401) {       
        setAlertCode("fail")
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 2000)     
        return null
      }
    })
    .then(data => {
      if (data) {
        setAlertCode(data.status === "ok" ? "ok" : "fail")
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
          if (test.enabled) window.close()
        }, 2000)
      }        
    })
  }

  return (
    <Box className="test_page">
      { status === "loading" 
      ?
          <Box sx={{ width: '100%', marginTop: 5 }}>
              <LinearProgress color="secondary" />
          </Box> 
      : <></> }
      { status === "401" 
      ?
          <FourOOne/>
      : <></> }
      { status === "200" 
      ?
        <>
          <Box className='actions_row'>
            <Box className="actions_row_left_container">              
    
            </Box>
            <Box className="actions_row_right_container">
                  <Tooltip title="Guardar cambios"> 
                      <IconButton onClick={() => {
                          saveTest()
                      }}>
                          <SaveIcon/>
                      </IconButton>
                  </Tooltip>
                  <Tooltip title="Correr test"> 
                      <IconButton disabled={test.engine === "manual" || !test.run_button_enable} onClick={() => {
                          scrap()
                      }}>
                          <CloudDownloadIcon/>
                      </IconButton>
                  </Tooltip>
                  <Tooltip title={ test.enabled ? "Archivar test" : "Desarchivar test" }> 
                      <IconButton onClick={() => {
                          archiveTest()
                      }}>
                          { test.enabled ? <ArchiveIcon/> : <UnarchiveIcon/> }
                      </IconButton>
                  </Tooltip>
                  <Tooltip title="Ver alertas"> 
                            <IconButton onClick={() => {
                          window.location.href = "/review/" + test_id
                      }}>
                          <NotificationsIcon/>
                      </IconButton>
                  </Tooltip>
            </Box>
          </Box>

          <Box className="test_container">
            <Box className="test_section_title"><h2>Definición</h2></Box>
            <Definition test={test} setTest={setTest}/>
            <Box className="test_section_title"><h2>Actividades afectadas</h2></Box>
            <Activities test={test} setTest={setTest}/>
            <Box className="test_section_title"><h2>Campaña activa</h2></Box>
            <Campaign test={test} setTest={setTest}/>
            <Box className="test_section_title"><h2>Pasos</h2></Box>
            <Steps test={test} setTest={setTest}/>
          </Box>
        </>
      : <></> }   

      { show_alert ? 
        <Box className="alert_container">
            <Alert severity={alert_code === "ok" ? "success" : "error"}>
            {alert_code === "ok" ? "Guardado" : "Algo ha ido mal"}
            </Alert>
        </Box>
        : <></> } 
    </Box>
  )
}
export default Test
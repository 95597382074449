import React, {useState} from "react";
import {
    TableRow,
    TableCell,
    IconButton,
    Box,
    Tooltip,
    Chip,
    Avatar
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import HistoryIcon from '@mui/icons-material/History';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import es from 'javascript-time-ago/locale/es'
import TimeAgo from 'javascript-time-ago'

import LogModal from "./LogModal";

TimeAgo.addDefaultLocale(es)
const timeAgo = new TimeAgo('en-US')

const ListRow = (props) => {

    const [log_modal_open, setLogModalOpen] = useState(false)

    const getUserName = (username) => {
        if (username in props.users)
          return props.users[username].name
        return username
    }      

    return (
        <>
        {
            log_modal_open ? <LogModal log={props.status.log} open={log_modal_open} close={setLogModalOpen}/> : <></>
        }
        <TableRow hover={true} key={props.test["_id"]}>
            <TableCell align='center'>
                { props.status.status === "ok" ? <Box className="status_flag status_flag_ok" onClick={() => setLogModalOpen(!log_modal_open)}>PASA</Box> : <></> }
                { props.status.status === "alert" ? <Box className="status_flag status_flag_alert" onClick={() => setLogModalOpen(!log_modal_open)}>ALERTA</Box> : <></> }
                { props.status.status === "fail" ? <Box className="status_flag status_flag_fail" onClick={() => setLogModalOpen(!log_modal_open)}>FALLO</Box> : <></> }
                { props.status.status === "queue" ? <Box className="status_flag status_flag_waiting" onClick={() => setLogModalOpen(!log_modal_open)}>EN COLA</Box> : <></> }
                { props.status.status === "running" ? <Box className="status_flag status_flag_waiting" onClick={() => setLogModalOpen(!log_modal_open)}>CORRIENDO</Box> : <></> }
            </TableCell>
            <TableCell align='center' style={{"color": props.test.alerts > 0 ? "red" : "black"}}>
                { props.test.alerts }
            </TableCell>
            <TableCell align='center'>
                { props.test.type }
            </TableCell>
            <TableCell align='center'>{props.test.engine}</TableCell>
            <TableCell>{props.test.name}</TableCell>
            <TableCell align='center'>{props.test.campaign === "fve" ? "FVE" : (props.test.campaign === "training" ? "Form." : (props.test.campaign === "no" ? "No" : props.test.campaign))}</TableCell>
            <TableCell align='center'>{props.test.ui_depends_on_os_version ? "Sí" : "No"}</TableCell>
            <TableCell align='center'>{props.test.run_in_bulk ? "Sí" : "No"}</TableCell>
            <TableCell align='center'>{props.test.user_interaction ? "Sí" : "No"}</TableCell>
            <TableCell>
                {
                typeof props.status.last_check === "string" || props.status.last_check instanceof String
                ? 
                    props.status.last_check
                :
                    timeAgo.format(props.status.last_check*1000)
                }
            </TableCell>
            <TableCell>
                {
                ((Date.now()/1000) - props.status.lock.timestamp) < 5
                ?
                    <Chip
                        avatar={<Avatar alt={getUserName(props.status.lock.username)} src={"/images/avatar/" + props.status.lock.username + ".jpg"} />}
                        label={getUserName(props.status.lock.username)}
                        variant="outlined"
                    />                                
                :
                <>
                    <Tooltip title="Ver alertas">
                        <IconButton onClick={() => {window.open("/review/" + props.test["_id"], '_blank').focus()}}>
                            <NotificationsIcon/>
                        </IconButton>
                    </Tooltip>                                                           
                    <Tooltip title="Editar test">
                        <IconButton onClick={() => {window.open("/test/" + props.test["_id"], '_blank').focus()}}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                     
                    {
                        props.test.engine === "manual"
                        ?
                            <Tooltip title="Actualizar tiempo">
                                <IconButton onClick={() => {props.updateTime(props.test["_id"], props.test.name)}}> <CheckCircleOutlineIcon /> </IconButton>
                            </Tooltip>
                        :
                        <Tooltip title="Ejecutar test">
                            {
                                props.status.status === "queue" || !props.test.run_button_enable
                                ? 
                                    <IconButton disabled> <CloudDownloadIcon /> </IconButton>
                                :
                                    <IconButton onClick={() => {props.scrap(props.test["_id"], props.test.name)}}> <CloudDownloadIcon /> </IconButton>
                            }
                        </Tooltip>
                    }
                    
                </>
                }
                <Tooltip title="Abrir histórico">
                    <IconButton onClick={() => {window.open("/history/" + props.test["_id"], '_blank').focus()}}>
                        <HistoryIcon/>
                    </IconButton>
                </Tooltip>
                
                <Tooltip title="Abrir web">
                    <IconButton onClick={() => {window.open(props.test.navigate[0].url, '_blank').focus()}}>
                        <OpenInNewIcon/>
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
        </>
    )

}

export default ListRow